<template>
  <div class="main-container">
    <div v-if="step === LoginSteps.LOGIN" class="login-container">
      <div class="login-box">
        <span class="login-title">
          Iniciar sesión
        </span>
        <span class="login-subtitle">
          ACCESO A LAS HERRAMIENTAS DE INVERSIÓN
        </span>
        <div class="login-field">
          <span class="login-icon material-icons">
            person
          </span>
          <input id="user" v-model="user" type="text" placeholder="Usuario...">
        </div>
        <div class="login-field">
          <span class="login-icon material-icons">
            lock
          </span>
          <input id="password" v-model="pass" v-on:keyup.enter="_userNameExists" type="password" placeholder="Contraseña...">
        </div>
        <div class="login-button" @click="_userNameExists">
          LOG IN
        </div>
        <a class="login-link" :href="forgot_password_link">
          He olvidado mi constraseña
        </a>
        <a class="login-link" :href="register_link">
          ¿No tienes cuenta? Regístrate
        </a>
      </div>
      <a class="outer-link" href="#" @click.prevent="toggleClass('member-faq')">
        ¿Cómo ser miembro de Eurekers?
      </a>
      <p id="member-faq" class="login-faq hide-tag">
        Para ser Eureker, es necesario haber realizado la formación sobre la metodología EUREKERS. Una vez finalizada, si lo deseas, pasarás a formar parte de la mayor comunidad de inversores de Europa. Una comunidad que en la actualidad cuenta con alrededor de 4.000 miembros activos, en la que se comparte información, experiencias y conocimientos.
      </p>
      <a class="outer-link" href="#" @click.prevent="toggleClass('web-faq')">
        ¿Qué encontraré dentro de la Web?
      </a>
      <p id="web-faq" class="login-faq hide-tag">
        Una de las mayores dificultades para los inversores es localizar los valores más adecuados para su cartera. Esta web de herramientas te facilita enormemente esta tarea, al permitirte aplicar filtros (rentabilidad, sectores, divisas..) que afinan la búsqueda al máximo hasta encontrar, en menos de un segundo, las mejores oportunidades de inversión para ti.</br></br>Dispones de herramientas que permiten analizar cualquier valor o índice que te interese, mira su precio de cotización y su volumen negociado, mide su revalorización o devaluación en un periodo determinado y detecta puntos de soporte y/o resistencia, entre otras funciones.</br></br>Estarás en contacto con una comunidad de mas de 4000 inversores de España, Europa y latinoamérica. Además, contarás con la colaboración de los administradores web y profesores de Eurekers y del propio José Antonio Madrigal, quienes siempre están atentos a vuestros comentarios.
      </p>
    </div>
    <div v-if="step === LoginSteps.TWO_FACTOR" class="two-factor-container">

      <div class="login-box">
        <span class="login-title">
          Autenticación de doble factor
        </span>
        <span class="login-subtitle">
          Antes de acceder, confirme su identidad
        </span>
        <div class="tfa-controls">
          <div class="tfa-send-subcontainer">
            <div :class="{ 'button-disabled': notifierOnCooldown }" class="login-button button-long"
              @click="!notifierOnCooldown ? requestCode() : pass">Enviar {{ formattedNotifier }}</div>
            <span v-if="notifierOnCooldown"> No puede volver a enviar un {{ formattedNotifier }} hasta las <br> {{
              formattedTime }} <br> ({{ remainingTime }})</span>
          </div>
          <a class="login-link" @click="changeNotifier">Prefiero {{ formattedNotNotifier }}</a>
        </div>
        <div class="login-field">
          <span class="login-icon material-icons">
            lock
          </span>
          <input id="code" v-model="code" type="text" placeholder="Código de verificación">
        </div>
        <div class="login-button button-long" @click="postVerify">
          Verificar código
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VueOnToast from "vue-on-toast";
import APICaller from "@/components/codeComponents/APICaller.js";
import { format } from "highcharts";
import Information_mobile from "../components/stockchart/components/Information_mobile.vue";

export default {
  mixins: [APICaller],
  name: 'Login',
  data() {
    return {
      //Consts
      LoginSteps: Object.freeze({
        LOGIN: 0,
        TWO_FACTOR: 1,
        LOGGED: 2
      }),
      UserIdentity: Object.freeze({
        UNKNOWN: 0,
        ANONYMOUS: 1,
        LOGGED: 2
      }),
      Notifiers: Object.freeze({
        UNKNOWN: 0,
        EMAIL: 1,
        SMS: 2
      }),
      forgot_password_link: process.env.VUE_APP_API + '/app/retrieve-password',
      register_link: process.env.VUE_APP_API + '/app/user-register',

      //Vars
      currentTime: Date.now(),
      step: undefined,
      user: '',
      pass: '',
      code: '',
      email_awaiting_response: false,
      sms_awaiting_response: false,
      cooldown_email: 0,
      cooldown_sms: 0,
      notifier: undefined,
      notifierCooldownExpiresAt: 0,
      user_exist: false,
      comes_from_informe_login: false
    }
  },
  watch: {
    step(newVal, oldVal) {
      if (newVal === this.LoginSteps.LOGIN) {
        this.clearLoginInputs();
      }
      else if (newVal === this.LoginSteps.TWO_FACTOR) {
        this.bootstrapTwoFactor();
      }
      else if (newVal === this.LoginSteps.LOGGED) {
        this.redirectLogged();
      }
    }
  },
  created() {
    this.timer = setInterval(() => {
      this.currentTime = Date.now();
    }, 1000);
    const previous = localStorage.getItem('user_location_before_login');
    if(previous == 'InformeLogin') {
      this.comes_from_informe_login = true;
    }
  },
  async mounted() {
    await this.waitUntilUserIdentityAvailable();
    if (this.currentUserIdentity === this.UserIdentity.ANONYMOUS) {
      this.step = this.LoginSteps.LOGIN;
    }
    else if (this.currentUserIdentity === this.UserIdentity.LOGGED) {
      this.step = this.LoginSteps.LOGGED;
    }
  },
  computed: {
    ...mapGetters(["_g_UserId"]),
    currentUserIdentity() {
      return this._g_UserId === undefined ? this.UserIdentity.UNKNOWN : this._g_UserId === 0 ? this.UserIdentity.ANONYMOUS : this.UserIdentity.LOGGED;
    },
    formattedNotifier() {
      return this.notifier === undefined ? '...' : this.notifier === 1 ? 'Email' : 'SMS';
    },
    formattedNotNotifier() {
      return this.notifier === undefined ? '...' : this.notifier === 1 ? 'SMS' : 'Email';
    },
    notifierOnCooldown() {
      const isEmailNotifier = this.notifier === this.Notifiers.EMAIL;
      const isEmailOnCooldown = this.cooldown_email !== 0 || this.email_awaiting_response;

      const isSmsNotifier = this.notifier === this.Notifiers.SMS;
      const isSmsOnCooldown = this.cooldown_sms !== 0 || this.sms_awaiting_response;

      return (isEmailNotifier && isEmailOnCooldown) || (isSmsNotifier && isSmsOnCooldown);
    },
    remainingTime() {
      const timestamp = this.notifier === this.Notifiers.EMAIL ? this.cooldown_email : this.notifier === this.Notifiers.SMS ? this.cooldown_sms : 0;
      return this.calculateRemainingTime(timestamp, this.currentTime);
    },
    formattedTime() {
      const timestamp = this.notifier === this.Notifiers.EMAIL ? this.cooldown_email : this.notifier === this.Notifiers.SMS ? this.cooldown_sms : 0;
      return this.formatToHHMMSS(timestamp);
    }
  },
  methods: {
    async waitUntilUserIdentityAvailable() {
      while (this.currentUserIdentity === this.UserIdentity.UNKNOWN) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    },

    async bootstrapTwoFactor() {
      this.clearLoginInputs();
      this.notifier = this.Notifiers.EMAIL;
      await this.getCooldowns();
    },

    async getCooldowns() {
      const url = process.env.VUE_APP_API + '/auth/cooldown';
      try {
        const response = await axios.get(url);
        this.handleCooldownResponse(response.data);
      } catch (error) {
        VueOnToast.ToastService.pop('error', `Error COOLDOWN_01`, 'Error recibiendo límites del notificador.');
      }
    },

    handleCooldownResponse(response) {
      const now = Date.now(); // Current time in milliseconds

      this.cooldown_email = response.email_cooldown_expiry > 0 && new Date(response.email_cooldown_expiry * 1000) > now
        ? response.email_cooldown_expiry
        : 0;

      this.cooldown_sms = response.sms_cooldown_expiry > 0 && new Date(response.sms_cooldown_expiry * 1000) > now
        ? response.sms_cooldown_expiry
        : 0;

      this.email_awaiting_response = false;
      this.sms_awaiting_response = false;
    },


    redirectLogged() {
      let ruta = "/app/graficador-dinamico";
      if(this.comes_from_informe_login) {
        ruta = "/app/informe/login";
      }
      
      const previous = localStorage.getItem('user_location_before_login');
      if(previous === 'InformeFlow') {
        ruta = "/app/informes";
        localStorage.removeItem('user_location_before_login');
      }
      
      window.location.href = process.env.VUE_APP_API + ruta;
    },

    async postLogin() {
      const url = process.env.VUE_APP_API + '/auth/login';
      if(this.user_exist){
        try {
          const params = new URLSearchParams();
          params.append('user', this.user);
          params.append('pass', this.pass);
          const response = await axios.post(url, params);
          this.handleLoginResponse(response.data);
        } catch (error) {
          VueOnToast.ToastService.pop('error', `Error LOGIN_01`, 'Error enviando datos de inicio de sesión.');
        }
      }else{
        this.$vueOnToast.pop('error', "Credenciales inválidas", "Nombre de usuario incorrecto.");
      }
    },

    _userNameExists: function(){
      let params = new URLSearchParams();
      params.append('username', this.user);

      let url = '/auth/username';

      let success = (response)=>{
        this.user_exist = response.data;
        this.postLogin();
      };

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('386', 'Error comprobando nombre de usuario.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    handleLoginResponse(response) {
      if (response.successful_login) {
        if (response.needs_2fa) {
          this.step = this.LoginSteps.TWO_FACTOR;
        } else {
          this.step = this.LoginSteps.LOGGED;
        }
      } else {
        VueOnToast.ToastService.pop('error', `Credenciales inválidas`, 'Contraseña incorrecta.');
        this.clearLoginInputs();
      }
    },

    async postVerify() {
      const url = process.env.VUE_APP_API + '/auth/verify';
      try {
        const params = new URLSearchParams();
        params.append('code', this.code);
        const response = await axios.post(url, params);
        this.handleVerifyResponse(response.data);
      } catch (error) {
        VueOnToast.ToastService.pop('error', `Error TFA_01`, 'Error enviando datos doble factor.');
      }
    },

    async requestCode() {
      this.email_awaiting_response = true;
      this.sms_awaiting_response = true;
      
      const notifier_api_name = this.notifier === this.Notifiers.EMAIL ? 'email' : 'sms';
      const notifier_popup_name = this.notifier === this.Notifiers.EMAIL ? 'email' : 'sms';
      const url = process.env.VUE_APP_API + '/auth/send-tfa-code/' + notifier_api_name;
      try {
        const response = await axios.get(url);
        if (response.data != false) {
          VueOnToast.ToastService.pop('success', `${notifier_popup_name} enviado con éxito`, 'Compruebe su bandeja de entrada.');
        }
        else {
          VueOnToast.ToastService.pop('error', `ERROR_${notifier_popup_name}_02`, `No se ha podido enviar el ${notifier_popup_name}`);
        }
        this.getCooldowns();
      } catch (error) {
        VueOnToast.ToastService.pop('error', `Error ${notifier_popup_name.toUpperCase()}_01`, `Error enviando el ${notifier_popup_name.toUpperCase()} con el código de verificación.`);
      }
    },

    handleVerifyResponse(response) {
      if (response.success) {
        this.step = this.LoginSteps.LOGGED;
      }
      else {
        VueOnToast.ToastService.pop('error', `Error en el código`, 'Código incorrecto o expirado.');
        this.clearCodeInput();
      }
    },

    clearLoginInputs() {
      this.user = '';
      this.pass = '';
    },

    clearCodeInput() {
      this.code = '';
    },

    changeNotifier() {
      this.notifier = this.notifier === this.Notifiers.EMAIL ? this.Notifiers.SMS : this.Notifiers.EMAIL;
    },

    formatToHHMMSS(timestamp) {
      const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      return `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`;
    },

    calculateRemainingTime(timestamp, currentTime) {
      const now = Math.floor(currentTime / 1000);
      const difference = timestamp - now;
      if (difference <= 0) {
        this.getCooldowns();
        return "00:00";
      }
      const minutes = Math.floor(difference / 60);
      const seconds = difference % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    toggleClass(faqId) {
      // Find the element based on the faqId
      const faqElement = document.getElementById(faqId);
      // Toggle the 'hide-tag' class on the found element
      if (faqElement) {
        faqElement.classList.toggle('hide-tag');
      }
      window.scrollTo(0, document.body.scrollHeight);
     }
  }
}
</script>

<style>

#fullscreen-button-container-all-pages, #launcher, #menu-container {
  display: none;
}

#grid-layout {
  margin-left: 0;
}

</style>

<style lang="scss" scoped>
/* css provisional */
.login-container,
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.login-button {
  padding: 12px 50px !important;
  border-radius: 30px !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  background-color: #00add8;
  color: white;
  font-weight: bold;
  cursor: pointer;
  width: 70px;
  margin-bottom: 15px;
  text-align: center;
}

.login-button:active {
  background-color: #008fb3;
}

.tfa-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 25px;
  height: 100%;
}

.tfa-controls,
.tfa-input {
  display: flex;
  flex-direction: column;
}

input[type="text"],
input[type="password"] {
  background-color: none !important;
  border: none !important;
  border-bottom: 1px solid #242632 !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 10px 30px !important;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #242632 !important;
  margin-bottom: 40px;
}

.login-icon {
  font-size: 20px;
  position: absolute !important;
  padding-top: 10px !important;
  color: #242632;
}

.login-title {
  color: #0F0F1A;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.15px;
  font-weight: 600;
  margin-bottom: 35px;
  font-size: 20px;
}

.login-subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 35px;
  font-size: 14px;
}

.login-title,
.login-subtitle {
  margin: 0;
  margin-bottom: 35px;
}

.login-link {
  cursor: pointer;
  text-align: center;
  color: #00add8;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.login-link:hover {
  color: #00c1f2;
  text-decoration: underline;
}

.login-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 50px 20px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
  width: max-content;
  margin-bottom: 54px;
  margin-top: 50px;
  padding: 75px 43px 62px;
  margin-bottom: 64px;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}

.outer-link {
  text-align: center;
  color: #1F7AA0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 13px;
  text-decoration: none;
  cursor: pointer;
}

.outer-link:hover {
  color: #00c1f2;
}

.button-long {
  width: 150px;
}

.tfa-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.tfa-send-subcontainer {
  display: flex;
  flex-direction: column;
  width: 250px;
  margin-bottom: 10px;
}

.tfa-send-subcontainer span {
  font-size: 12px;
}

.button-disabled,
.button-disabled:hover {
  cursor: auto;
  background-color: #93c9d6 !important;
}
</style>

<style scoped>
#menu-container,
#launcher,
#fullscreen-button-container-all-pages {
  display: none;
}

#grid-layout {
  margin-left: 0;
}

.login-faq {
  max-width: 440px;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: #777;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.hide-tag {
  display: none;
}
</style>